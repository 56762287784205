@import "../../../assets/styles/global";

.heading_wrapper {
    width: 100%;
    text-align: center;
    &.text_centered {
        justify-content: center;
        display: flex;
    }
    .headingFirst {
        color: #1d2126;
        font-family: OnestMedium;
        font-size: 32px;
        font-style: normal;
        line-height: 44.8px;
        text-align: left;
    }

    .textRegular {
        color: #5b636d;
        font-family: OnestRegular;
        font-size: 16px;
        font-style: normal;
        line-height: 25.6px;
    }
}
