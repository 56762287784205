.error-label {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    & > svg {
        margin-right: 5px;
    }
    &.red {
        color: #e23d3d;
        svg {
            fill: #e23d3d;
        }
    }
    &.blue {
        color: #2f80ed;
        svg {
            fill: #2f80ed;
        }
    }
    &.green {
        color: #20b053;
        svg {
            fill: #20b053;
        }
    }
    &.yellow {
        color: #ff9700;
        svg {
            fill: #ff9700;
        }
    }
    &.gray {
        color: #8c969b;
        svg {
            fill: #8c969b;
        }
    }
    &.dark-gray {
        color: #5B636D;
        svg {
            fill: #5B636D;
        }
    }
}
