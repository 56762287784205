@mixin sign-page-adaptive {
    .grid_wrapper {
        flex-direction: column !important;
        .bg_image_container {
            width: 100% !important;
            height: 30vh !important;
        }
        .signin_container {
            width: 100% !important;
            height: unset !important;
            box-sizing: border-box;
            padding: 40px;
            .main_container {
                width: auto !important;
            }
        }
    }
}
