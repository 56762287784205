.info_block {
    background-color: #fff;
    border: 1px solid #e1e6ed;
    margin-top: -1px;
    margin-bottom: 20px;
    .info_block_content {
        padding: 15px;
    }
}
.info_block_title {
    background-color: #f4f5f7;
    padding: 20px 15px;
}
.info_block_input_container {
    border-bottom: 1px solid #e1e6ed;
    display: flex;
    padding: 10px 0px;
    justify-content: space-between;
    &.endAdornment > div {
        max-width: 90px;
    }
    legend {
        display: none;
    }
    .percent_width {
        max-width: 90px;
    }
    label {
        display: none;
    }
    &:last-child {
        border-bottom: none;
    }

    & > label {
        width: 50%;
        display: flex;
        align-items: center;
    }
    & > div {
        flex: 1;
    }
}
.logo_input_container {
    position: relative;
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .img_container {
        width: 50px;
        height: 50px;
        border: 1px solid #ced3db;
        border-radius: 100%;
        margin: 10px;
        background-position: center !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
    }
    .close_remove_icon {
        position: absolute;
        right: 10px;
    }
}
