.top_content {
    background-color: #fff;
    // padding: 15px;
    border: 1px solid #e1e6ed;
    margin-top: -1px;
    display: flex;
    &.center_position{
        justify-content: center;
        display: flex;
        align-items: center;
        min-height: 500px;
    }
   

    .left_forms,
    .right_forms {
        flex: 1;
    }
    .left_forms {
        padding: 20px 10px 20px 20px;
       
    }
    .right_forms {
        padding: 20px 20px 20px 10px;
    }
}
.counts_container{
    min-height: 261px;
    &.counts_is_fetching {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.actions_buttons {
    border: 1px solid #e1e6ed;
    margin-top: -1px;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0px -3px 12px 0px rgba(25, 29, 45, 0.07);
    border-radius: 0px 0px 10px 10px;
    button {
        text-transform: capitalize;
        font-family: OnestRegular;
        margin-left: 10px;
        // width: 100px !important;
    }
}
.save_button {
    background-color: #e1e6ed;
    border: 1px solid #ccd3dd;
    box-shadow: none;
    line-height: 30px;
    padding: 5px 30px !important;
    svg path {
        fill: #fff;
        width: 20px;
        height: 20px;
    }
    &:hover {
        background-color: #e1e6ed;
    }
}
.info_block {
    background-color: #fff;
    border: 1px solid #e1e6ed;
    margin-top: -1px;
    margin-bottom: 20px;
    .info_block_content {
        border-bottom: 1px solid #e1e6ed;
        padding: 5px 15px;
    }
}
.info_block_title {
    background-color: #f4f5f7;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.info_block_title {
    background-color: #f4f5f7;
    padding: 10px 15px;
}
.info_block_input_container {
    // border-bottom: 1px solid #e1e6ed;
    display: flex;
    padding: 10px 0px;

    &:last-child {
        border-bottom: none;
    }

    & > label {
        width: 50%;
        display: flex;
        align-items: center;
    }
    & > div {
        flex: 1;
    }
}
