@import './assets/styles/global.scss';

@font-face {
  font-family: "OnestRegular";
  src: local("OnestRegular"), url("./assets/fonts/onest/Onest-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "OnestMedium";
  src: local("OnestMedium"), url("./assets/fonts/onest/Onest-Medium.ttf") format("truetype");
  font-weight: 500;
}

body {
  font-family: OnestRegular;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: unset !important;
}

.kyc-super-section {
  flex-direction: column;
  display: flex;
  width: 100%;
  margin: 0 auto;
  &-no-scroller {
    overflow: hidden;
  }
  @include desktop-and-above {
    min-width: 1200px;
    display: flex;
  }
}