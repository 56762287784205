@import "../../../assets/styles/global";
.empty_page_content {
    width: 100%;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #e1e6ed;
    background: var(--White, #fff);
}
