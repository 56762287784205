@import "../../assets/styles/global.scss";
.licenses_container {
    display: flex;
    flex-direction: column;
    & > div {
        margin-bottom: 20px;
        & > div {
            width: 100%;
        }
    }

    .textarea {
        min-height: 200px;
    }
}
.customer_page_bottom_container {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    button {
        line-height: 30px;
    }
}
.calendar_and_filters_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.select_period {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    font-family: OnestRegular;
    label {
        color: #5b636d;
    }
    div {
        font-family: OnestRegular;
        color: #5b636d;
    }
    & > svg {
        margin-right: 10px;
    }
    fieldset {
        border: none;
    }
}
.add_license_btn {
    background-color: #e7f1ff !important;
    color: #2f80ed !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    text-transform: unset !important;
    padding: 7px 8px !important;
}
.licenses_page_bottom_container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.export_csv {
    color: #2f80ed;
    font-size: 16px;
    font-family: OnestRegular;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: none;
    svg {
        margin-left: 15px;
    }
}
.table_header {
    background-color: #ebf0f7;
    > div > div > div > div {
        color: #5b636d;
        font-weight: 600;
    }
}