@import "../../../assets/styles/global";
.warning_wrapper {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    background: #ffb800;

    box-shadow:
        0px 0px 4px 0px rgba(0, 0, 0, 0.04),
        0px 3px 9.8px 0px rgba(0, 0, 0, 0.08);

    .star_proccess {
        border: none !important;
    }
    label {
        display: flex;
        align-items: center;
        svg {
            margin-right: 10px;
        }
    }
}

.custom_modal_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    min-width: 600px;
    position: relative;
    padding-bottom: 20px;

    .close_icon {
        position: absolute;
        right: 0px;
        top: 0px;
        margin: 20px;
        cursor: pointer;
    }

    .approve_button {
        background-color: #1d2126 !important;
        color: #fff !important;
        border: none !important;
        min-width: 215px;
        &:hover {
            // .size-l.btn-secondary:hove
            background-color: #35383b !important;
        }
    }

    .cancel_button {
        color: #8c969b !important;
        border: none !important;
        &:hover {
            // .size-l.btn-secondary:hove
            background-color: #35383b !important;
        }
    }
    .setting_modal_title {
        display: flex;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 0px 0px;
    }
    .modal_content {
        width: 100%;
        box-sizing: border-box;
        padding: 0px;
    }

    .action_buttons {
        button {
            padding: 19px 22px;
        }
        .btn-tertiary {
            color: #8c969b;
        }
    }
}
