@import "../../assets/styles/global";
// * {
//     font-family: OnestRegular;
// }
.customer_page_bottom_container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    button {
        line-height: 30px;
    }
}
.svg_white {
    svg {
        font-size: 20px;
        width: 30px !important;
        path {
            fill: #fff;
        }
    }
}
div{
    outline: none !important;
}
.action_row_container{
    display: none;
}
div[role=row]:hover{
    .action_row_container{
        display: block;
    }
}
.bold_black{
    color: #1D2126;
    font-weight: 700;
}
.bold_blue{
    color: #2F80ED;
}
.table_header {
    background-color: #ebf0f7;
    > div > div > div > div {
        color: #5b636d;
        font-weight: 600;
    }
}

.companies_page{
   
    .Mui-hovered{
        border: 1px solid #ff0000;
    }
    .action_buttons {
        // &:hover
        &> div svg{
           opacity: 0.2;
        }
        &> div:hover svg{
            opacity: 1;
        }
        &> div:hover .pencil_icon:hover path{
            fill:#008a60;
        }
        &> div:hover .trash_icon:hover path{
            fill:#af0505;
        }
    }
}
.custom_modal_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    min-width: 500px;
    position: relative;
    padding: 30px;

    .close_icon {
        position: absolute;
        right: 0px;
        top: 0px;
        margin: 20px;
        cursor: pointer;
    }
    .cancel_button{
        background-color: #E23D3D !important;
        color: #fff!important;
        border: none!important;
        &:hover{
            // .size-l.btn-secondary:hove
            background-color: #b82222 !important;  
        }
    }
}
.action_buttons {
    svg {
        cursor: pointer;
        rect {
            fill: none;
        }
    }
}
.table_container {
    &.is_fetching {
        justify-content: center;
        display: flex;
        align-items: center;
        min-height: 300px;
    }
}
