.custom_modal_container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    min-width: 600px;
    min-height: 550px;
    position: relative;
    padding-bottom: 20px;
    

    .close_icon {
        position: absolute;
        right: 0px;
        top: 0px;
        margin: 20px;
        cursor: pointer;
    }
    .is_disabled{
        background-color: #e1e6ed !important;
        min-width: 215px;
        &:hover {
            // .size-l.btn-secondary:hove
            background-color: #e1e6ed !important;
        }
    }
    .cancel_button {
        background-color: #1D2126 !important;
        color: #fff !important;
        border: none !important;
        min-width: 215px;
        // &:hover {
        //     // .size-l.btn-secondary:hove
        //     background-color: #b82222 !important;
        // }
    }
    .setting_modal_title {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
    }
    .modal_content {
        width: 100%;
        box-sizing: border-box;
        padding: 0px;
        // min-height: 380px;
    }
    .tabs_items_container {
        display: flex;
        justify-content: flex-start;
        padding: 0px 20px;

        .tabs_item {
            display: flex;
            padding: 9px 26px;
            justify-content: center;
            align-items: center;
            border-radius: 0px;
            border: 1px solid #e1e6ed;
            background: #fafbfc;

            color: #8c969b;
            font-family: OnestRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            max-width: 130px;
            cursor: pointer;
            margin-right: -1px;

            &:first-child {
                border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
                border-radius: 0px 4px 4px 0px;
            }
            &.active {
                background: #2f80ed;
                color: #f4f5f7;
            }
        }
    }

    .tabs_content {
        width: 100%;
        .tab_content_item {
            display: none;
            &.active_content {
                display: flex;
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }
    }

    .action_buttons {
        button {
            padding: 19px 22px;
        }
    }
    .settings_block_title {
        background: #f4f5f7;
        display: flex;
        padding: 16px 28px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        color: #1d2126;
        font-family: OnestRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .settings_block_content {
        display: flex;
        padding: 12px 28px;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        border-bottom: 1px solid #E1E6ED;
        &:last-child{
            border-bottom: none;
        }
    }
    .settings_content_block_title {
        color: #5b636d;
        font-family: OnestRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        justify-content: flex-start;
        display: flex;
    }
    .settings_block_description {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .description_text {
            color: #8c969b;
            font-family: OnestRegular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            text-align: left;
            margin-top: 10px;
            width: 80%;
        }
    }
    .description_action {
        input,
        p {
            font-family: OnestRegular;
        }
    }
    .settings_block {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .select_lang_container_parent {
        width: fit-content;
        // position: absolute;
        // top: 0px;
        // right: 0px;
        // margin: 15px;
        .select_lang_container {
            position: relative;
            background-color: #fafbfc;
            & > svg {
                position: absolute;
                top: 10px;
                left: 4px;
            }
            & > div {
                outline: none !important;
                svg {
                    fill: #5b636d;
                }
                & > div {
                    padding: 10px 30px 10px 30px;
                    border: 2px solid #e1e6ed;
                    color: #1d2126;
                    font-weight: 500;
                }
            }
        }
    }
}

.service_modal {
    z-index: 9999 !important;
    .custom_modal_container {
        min-height: 200px;
        .setting_modal_title {
            justify-content: center;
            padding-bottom: 0px;
        }
        .cancel_button{
            background-color: #FF9700!important;
            min-width: 215px;
            &:hover {
                // .size-l.btn-secondary:hove
                background-color: #cc7d06 !important;
            }
        }
    }
}
